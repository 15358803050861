import React from "react";
import propTypes from "prop-types";
import { formatPrice } from '../helpers';

class Fish extends React.Component {
    static propTypes = {
        details: propTypes.shape({
            image: propTypes.string,
            name: propTypes.string,
            desc: propTypes.string,
            status: propTypes.string,
            price: propTypes.number
        }),
        addToOrder: propTypes.func,
    };
    render() {
        const { name, price, status, desc, image } = this.props.details;
        const isAvailable = status === 'available';
        return (
            <li className="Fish, menu-fish">
                <img src={image} alt={name} />
                <h3 className="fish-name">{name}
                    <span className="price">{formatPrice(price)}</span>
                </h3>
                <p>{desc}</p>
                <button disabled={!isAvailable} onClick={() => this.props.addToOrder(this.props.index) }>
                    {isAvailable? 'Add to Order' : 'Sold Out!'}
                </button>
            </li>
        )
    }
}

export default Fish; 