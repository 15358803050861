import React from 'react';
import propTypes from 'prop-types';

class EditFishForm extends React.Component {
    static propTypes = {
        fish: propTypes.shape({
            image: propTypes.string,
            name: propTypes.string,
            desc: propTypes.string,
            status: propTypes.string,
            price: propTypes.number
        }),
        index: propTypes.string,
        updateFish: propTypes.func
    };
    
    handleChange = (event) => {
        console.log(event.currentTarget.name);
        // update that fish
        // 1. take a copy of the current fish
        const updatedFish = { 
            ...this.props.fish,
            [event.currentTarget.name]: event.currentTarget.value
        };
        this.props.updateFish(this.props.index, updatedFish)
    };
    render() {
        return <div className="fish-edit">
            <input 
                type="text" 
                name="name" 
                title="Name"
                onChange={this.handleChange} 
                value={this.props.fish.name}
            />
            <input 
                type="text" 
                name="price" 
                title="Price"
                onChange={this.handleChange} 
                value={this.props.fish.price} 
            />
            <select 
                type="text" 
                name="status" 
                title="Availability"
                onChange={this.handleChange} 
                value={this.props.fish.status}
            >
                <option value="available">Fresh!</option>
                <option value="unavailable">Sold out!</option>
            </select>
            <textarea 
                name="desc" 
                title="Description"
                onChange={this.handleChange} 
                value={this.props.fish.desc} 
            />
            <input 
                type="text" 
                name="image" 
                title="Image"
                onChange={this.handleChange} 
                value={this.props.fish.image} 
            />
            <button onClick={() => this.props.deleteFish(this.props.index)}>Remove Fish</button>
        </div>
    }
}

export default EditFishForm;