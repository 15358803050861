import Rebase from "re-base";
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAo1C7yNzFE4HeHRe83aibKsSko4DBqmu4",
    authDomain: "catch-of-the-day-schuy-h.firebaseapp.com",
    databaseURL: "https://catch-of-the-day-schuy-h-default-rtdb.firebaseio.com",
    appId: "1:930759894769:web:7d7509eae324b49cf2eb50"

})

const base = Rebase.createClass(firebaseApp.database());

// named export
export { firebaseApp };

// default export
export default base;