import React from "react";

const NotFound = () => (
    <div>
        <br />
        <h2>404 Error<br />
        <br />Page Not Found</h2>
    </div>
);

export default NotFound;