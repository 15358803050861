import React from "react";
import propTypes from "prop-types";
import Header from './Header';
import Order from './Order';
import Inventory from './Inventory';
import sampleFishes from '../sample-fishes'
import Fish from './Fish';
import base from "../base";

class App extends React.Component {
    state = {
        fishes: {},
        order: {}
    };

    static propTypes = {
        match: propTypes.object
    };

    componentDidMount() {
        const { params } = this.props.match;
        //first reinstate local storage
        const localStorageRef = localStorage.getItem(params.storeID);
        if(localStorageRef) {
            this.setState({ order: JSON.parse(localStorageRef) });
        }
        this.ref = base.syncState(`${params.storeID}/fishes`, {
            context: this,
            state: "fishes"
        });
    };

    componentDidUpdate() {
        localStorage.setItem(this.props.match.params.storeID, JSON.stringify(this.state.order));
    };

    componentWillUnmount() {
        base.removeBinding(this.ref);
    };
    // fishes
    addFish = (fish) => {
        // 1. Take a copy of the existing state
        const fishes = {...this.state.fishes};
        // 2. Add the new fish to that fishes variable
        fishes[`fish${Date.now()}`] = fish;
        // 3. Set the new fishes object to state
        this.setState({ fishes });
    };
    updateFish = (key, updatedFish) => {
        // 1. Take a copy of the current state
        const fishes = { ...this.state.fishes };
        // 2. Update that state
        fishes[key] = updatedFish;
        // 3. Set that to state
        this.setState({ fishes });
    };
    deleteFish = (key) => {
        // 1. Take a copy of state
        const fishes = { ...this.state.fishes };
        // 2. Update the state
        // Note: if you want to remove the fish from Firebase, you must set it to null
        fishes[key] = null;
        // 3. Update state
        this.setState({ fishes });
    }
    loadSampleFishes = () => {
        this.setState({ fishes: sampleFishes });
    };
    // order
    addToOrder = (key) => {
        // 1. Take a copy of state
        const order = {...this.state.order};
        // 2. Add to or update our order
        order[key] = order[key] + 1 || 1;
        // 3. Update state
        this.setState({ order });
    };
    removeFromOrder = (key) => {
        // 1. Take a copy of state
        const order = {...this.state.order};
        // 2. Remove that item from our order
        delete order[key];
        // 3. Update state
        this.setState({ order });
    };

    render() {
        return (
            <div className="catch-of-the-day">
                <div className="menu">
                    <Header tagline="Fresh Daily"/>
                    <ul className="fishes">
                        {Object.keys(this.state.fishes).map(key => (
                            <Fish 
                                key={key} 
                                index={key} 
                                details={this.state.fishes[key]} 
                                addToOrder={this.addToOrder} 
                            />
                        ))}
                    </ul>
                </div>
                <Order 
                    fishes={this.state.fishes} 
                    order={this.state.order} 
                    removeFromOrder={this.removeFromOrder}
                />
                <Inventory 
                    addFish={this.addFish} 
                    updateFish={this.updateFish}
                    deleteFish={this.deleteFish}
                    loadSampleFishes={this.loadSampleFishes}
                    fishes={this.state.fishes} 
                    storeID={this.props.match.params.storeID}
                />
            </div>
        );
    }
}

export default App;