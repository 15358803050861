export function formatPrice(cents) {
  return (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
}

export function rando(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export function getFunName() {
  const adjectives = [
    // "adorable",
    "beautiful",
    // "clean",
    // "drab",
    "elegant",
    "fancy",
    "glamorous",
    "handsome",
    "lovely",
    "magnificent",
    "old-fashioned",
    "plain",
    "quaint",
    "sparkling"
  ];

  const nouns = [
    "women",
    "men",
    // "children",
    "people",
    "leaves",
    "husbands",
    "wives",
    "lives",
    "loaves",
    "potatoes",
    "tomatoes",
    "carrots"
  ];

  return `${rando(adjectives)}-${rando(adjectives)}-${rando(nouns)}`;
}
