import React from "react";
import propTypes from "prop-types";
import firebase from 'firebase';
import AddFishForm from "./AddFishForm";
import EditFishForm from "./EditFishForm";
import Login from './Login';
import base, { firebaseApp } from "../base";

class Inventory extends React.Component {
    static propTypes = {
        fishes: propTypes.object,
        updateFish: propTypes.func,
        deleteFish: propTypes.func,
        loadSampleFishes: propTypes.func,
        addFish: propTypes.func
    };

    state = {
         uid: null,
         owner: null
    };

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.authHandler({ user });
            }
        });
    }

    authHandler = async (authData) => {
        // 1. Look up the current store in the firebase database
        const store = await base.fetch(this.props.storeID, { context: this });
        console.log(store);
        // 2. Claim it if there is no owner
        if (!store.owner) {
            // Save it as our own
            await base.post(`${this.props.storeID}/owner`, {
                data: authData.user.uid
            });
        }
        // 3. Set the state of the inventory component to reflect the current user
        this.setState({
            uid: authData.user.uid,
            owner: store.owner || authData.user.uid
        });
    };

    authenticate = (provider) => {
        const authProvider = new firebase.auth[`${provider}AuthProvider`]();
        firebaseApp
            .auth()
            .signInWithPopup(authProvider)
            .then(this.authHandler);
    };

    logout = async () => {
        console.log('Logging out');
        await firebase.auth().signOut();
        this.setState({ uid: null });
    }

    render() {
        const logout = <button class="logout" onClick={this.logout}>Sign Out!</button>;

        // 1. Check if the user is logged in
        if (!this.state.uid) {
            return <Login authenticate={this.authenticate} />;
        }

        // 2. Check if they are not the owner of the store 
        if (this.state.uid !== this.state.owner) {
            return (
                <div>
                    <h2>Inventory</h2>
                    {logout}
                    <p>Sorry, only the store owner can manage inventory.</p>
                </div>
            );
        }

        // 3. They must be the owner, just render the inventory
        return (
            <div className="inventory">
                <h2>Inventory</h2>
                {logout}
                {Object.keys(this.props.fishes).map(key => (
                    <EditFishForm 
                        key={key} 
                        index={key} 
                        fish={this.props.fishes[key]} 
                        updateFish={this.props.updateFish}
                        deleteFish={this.props.deleteFish}
                    />
                ))}
                <AddFishForm addFish={this.props.addFish}/>
                <button onClick={this.props.loadSampleFishes}>
                    Load Sample Fishes
                </button>
            </div>
        )
    }
}

export default Inventory;