import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import { getFunName } from "../helpers";

class StorePicker extends React.Component {
    myInput = React.createRef();

    static propTypes = {
        history: propTypes.object
    };

    goToStore = event => {
        event.preventDefault();
        const storeName = this.myInput.current.value;
        this.props.history.push(`/store/${storeName}`);
    };
    render() {
        return (
        <Fragment>
            {/* comment */}
            <form className="store-selector" onSubmit={this.goToStore}>
                <h2>Please Enter a Store</h2>
                <input
                type="text" 
                ref={this.myInput}
                required 
                placeholder="Store Name" 
                defaultValue={getFunName()}
                />
                <button type="submit">Visit Store →</button>
            </form>
            <p class="store-selector">Please enter the name of the store you are looking for in the text field above.</p>
        </Fragment>
        );
    }
}

export default StorePicker;